import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GetGatewaysList } from 'core/domain/gateways/repositories/getGatewaysList';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { GatewaysListDataModel } from 'core/domain/gateways/model';
import { useMessage } from 'hooks/useMessage';
import { GatewaysListColumns, GatewaysListColumnsTranslations } from './GatewaysListColumns';
import { getProjectPathById } from 'components/pages/App/routes/projects/config';
import { getAssetPathById } from 'components/pages/App/routes/assets/config';
import { GatewaysOptions, CustomGatewaysListDataModel } from './utils';
import { useAssignToAsset } from './useAssignToAsset';
import { useUnAssignToAsset } from './useUnAssignToAsset';
import { useAssignToProject } from './useAssignToProject';
import { useUnAssignToProject } from './useUnAssignToProject';
import { getGatewaysListWithPaginationPath } from 'components/pages/App/routes/gateways/config';
import {
  TABLE_PAGE_SIZE_DEFAULT_OPTIONS,
  TABLE_PAGINATION_PAGE_DEFAULT,
  TABLE_PAGINATION_SIZE_DEFAULT,
  TablePaginationSearchValue,
} from 'constants/table';
import { useDeleteGateway } from './useDeleteGateway';

const TYPE_PRIVATE = 'private';

export const useGatewaysList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [gateways, setGateways] = useState<CustomGatewaysListDataModel[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageSize, setPageSize] = useState<number>(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [totalPages, setTotalPages] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [searchQuery, setSearchQuery] = useState<string>('');
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);
  const {
    clientsOptions: clientsAssetsOptions,
    projectsOptions: projectsAssetsOptions,
    assetsOptions,
    assignToAssetModalOptions,
    onOpenAssignToAssetModal,
    successAssigningGateway,
    assigningToAsset,
  } = useAssignToAsset();
  const {
    successUnAssigningGateway: successAssigningGatewayToAsset,
    onOpenUnassignToAssetModal,
    unAssigningToAssetModalOptions,
  } = useUnAssignToAsset();
  const {
    assignToProjectModalOptions,
    assigningToProject,
    clientsOptions: clientsProjectOptions,
    projectsOptions: projectsProjectsOptions,
    onOpenAssignToProjectModal,
    successAssigningGatewayToProject,
  } = useAssignToProject();
  const {
    onOpenUnAssignToProjectModal,
    successUnAssigningGateway: successUnAssigningGatewayToProject,
    unAssigningToProjectModalOptions,
  } = useUnAssignToProject();
  const { onOpenDeleteGatewayModal, deleteGatewayOptions, successDeletingGateway } = useDeleteGateway();

  const title: string = t('_GATEWAYS_LIST_TITLE');

  const searchButtonText: string = t('search_with_ellipsis');

  const gatewayListColumnsTranslations: GatewaysListColumnsTranslations = {
    actionsTitle: t('actions'),
    assetAliasText: t('asset'),
    projectAliasText: t('project'),
    typeTitle: t('type'),
    privateTypeText: t('_GATEWAYS_LIST_COLUMNS_TYPE_PRIVATE'),
    publicTypeText: t('_GATEWAYS_LIST_COLUMNS_TYPE_PUBLIC'),
    notReportedText: t('not_reported'),
    notAssignedText: t('_GATEWAYS_LIST_NOT_ASSIGNED'),
    assignGatewayToAssetText: t('_GATEWAYS_LIST_ASSIGN_TO_ASSET'),
    unassignGatewayToAssetText: t('_GATEWAYS_LIST_UNASSIGN_TO_ASSET'),
    assignGatewayToProjectText: t('_GATEWAYS_LIST_ASSIGN_TO_PROJECT'),
    unassignGatewayToProjectText: t('_GATEWAYS_LIST_UNASSIGN_TO_PROJECT'),
    deleteGatewayButtonText: t('_GATEWAYS_LIST_DELETE_BUTTON_TEXT'),
  };

  const onSelectProject = (projectId: string) => {
    const projectRoute = getProjectPathById({ projectId });
    history.push(projectRoute);
  };

  const onSelectAsset = (assetId: string) => {
    const assetRoute = getAssetPathById(assetId);
    history.push(assetRoute);
  };

  const columns = GatewaysListColumns({
    translations: gatewayListColumnsTranslations,
    onSelectProject,
    onSelectAsset,
    onOpenAssignToAssetModal,
    onOpenUnassignToAssetModal,
    onOpenAssignToProjectModal,
    onOpenUnAssignToProjectModal,
    onOpenDeleteGatewayModal,
  });

  const onChangePage = (page: number, size?: number) => {
    setCurrentPage(page);
    setPageSize(size!);
    const filteredSize = String(!!size ? size : pageSize);
    history.push(getGatewaysListWithPaginationPath({ page: String(page), size: filteredSize }));
  };

  const payloadToCustomGatewayData = (gatewayData: GatewaysListDataModel): CustomGatewaysListDataModel => {
    const hasAssetsAssignedToGateway = !!gatewayData.assets.length;
    const isProjectAssignedToGateway = !!gatewayData.projectId;
    const isGatewayPrivate = gatewayData.type === TYPE_PRIVATE;

    return {
      ...gatewayData,
      actions: {
        isAssetActionsDisabled: !isGatewayPrivate,
        isAssignedToAsset: isGatewayPrivate && hasAssetsAssignedToGateway,
        isProjectActionsDisabled: hasAssetsAssignedToGateway,
        isAssignedToProject: !isGatewayPrivate && isProjectAssignedToGateway,
      },
    };
  };

  const transformGatewaysToCustomGateways = (gatewaysData: GatewaysListDataModel[]): CustomGatewaysListDataModel[] => {
    return gatewaysData.map((gatewayData) => payloadToCustomGatewayData(gatewayData));
  };

  const getGatewaysList = async ({ page, size, search }: { page: number; size: number; search?: string }) => {
    setLoading(true);
    try {
      const {
        data,
        meta: { totalPages },
      } = await GetGatewaysList({ host, token, pageNumber: page, pageSize: size, search });
      const transformedGateways = transformGatewaysToCustomGateways(data);
      setTotalPages(totalPages);
      setGateways(transformedGateways);
    } catch (error) {
      setMessageError({ description: t('_GATEWAYS_LIST_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (value: string) => {
    setSearchQuery(value);
    setCurrentPage(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  };

  useEffect(() => {
    const parsedPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
    const parsedSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
    setCurrentPage(Number(parsedPage));
    setPageSize(Number(parsedSize));
    getGatewaysList({ page: Number(parsedPage), size: Number(parsedSize), search: searchQuery });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, searchQuery]);

  useEffect(() => {
    if (
      !!successAssigningGateway ||
      !!successAssigningGatewayToAsset ||
      !!successAssigningGatewayToProject ||
      !!successUnAssigningGatewayToProject ||
      !!successDeletingGateway
    ) {
      const parsedPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
      const parsedSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
      getGatewaysList({ page: Number(parsedPage), size: Number(parsedSize) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    successAssigningGateway,
    successAssigningGatewayToAsset,
    successAssigningGatewayToProject,
    successUnAssigningGatewayToProject,
    successDeletingGateway,
  ]);

  const gatewaysOptions: GatewaysOptions = {
    gateways,
    columns,
    totalPages,
    currentPage,
    pageSize,
    searchButtonText,
    defaultPageSize: TABLE_PAGINATION_SIZE_DEFAULT,
    pageSizeOptions: TABLE_PAGE_SIZE_DEFAULT_OPTIONS,
    onChangePage,
    onSearch,
    loading,
  };

  return {
    title,
    assetsOptions,
    clientsAssetsOptions,
    clientsProjectOptions,
    projectsAssetsOptions,
    projectsProjectsOptions,
    gatewaysOptions,
    assignToAssetModalOptions,
    assignToProjectModalOptions,
    unAssigningToAssetModalOptions,
    unAssigningToProjectModalOptions,
    deleteGatewayOptions,
    assigningToAsset,
    assigningToProject,
  };
};
