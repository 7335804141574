import { FC } from 'react';
import TabsAtom, { TabsAtomProps } from 'components/atoms/TabsAtom';
import { HotelAccessesTabType, TabTranslations } from '../../resources/utils';

export interface OfficeTabsProps extends TabsAtomProps {
  translations: TabTranslations;
  activeHotelKey: HotelAccessesTabType;
}

export const HotelTabs: FC<OfficeTabsProps> = ({
  activeHotelKey,
  translations,
  ...props
}) => {
  const { accessListText, checkInListText } = translations;

  return (
    <TabsAtom defaultActiveKey={activeHotelKey} activeKey={activeHotelKey} {...props}>
      <TabsAtom.TabPane
        tab={<span>{accessListText}</span>}
        key={HotelAccessesTabType.ACCESS_LIST}
      />
      <TabsAtom.TabPane
        tab={<span>{checkInListText}</span>}
        key={HotelAccessesTabType.CHECK_IN_LIST}
      />
    </TabsAtom>
  );
};
