export enum HotelAccessesTabType {
  ACCESS_LIST = 'access-list',
  CHECK_IN_LIST = 'check-in-list',
}

export enum OfficeAccessesTabType {
  LOG_LIST = 'log-list',
}

export enum RouteSegmentType {
  DEFAULT_SEGMENT = 3,
  LAST_CLIENT_AND_PROJECT = 7,
  CLIENT_AND_PROJECT_SEGMENT = 8,
  ACCESS_LIST_SEGMENT = 9,
  ACCESS_DETAIL_SEGMENT = 10,
}

export interface TabTranslations {
  accessListText: string;
  checkInListText: string;
  logListText: string;
}
