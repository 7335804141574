export enum LocalStorageAccessKeys {
  CLIENT_ID = 'ACCESS_CLIENT_ID',
  PROJECT_ID = 'ACCESS_PROJECT_ID',
  PROJECT_TYPE = 'ACCESS_PROJECT_TYPE',
  TAB = 'ACCESS_PROJECT_TAB',
}

export interface AccessKeys {
  clientId?: string;
  projectId?: string;
  projectType?: string;
  tab?: string;
}

export enum LocalStorageAccessCheckInKeys {
  NO_SHOW_AGAIN_CHECK_OUT_MODAL = 'ACCESS_CHECK_IN_NO_SHOW_CHECK_OUT'
}

export interface AccessCheckInKeys {
  checked?: boolean;
}