import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { useTranslation } from 'react-i18next';
import { Menu, Switch } from 'antd';
import { ReactElement } from 'react';
import ContextualMenu from 'components/atoms/ContextualMenu';
import SpaceAtom from 'components/atoms/SpaceAtom';
import { SensorEntityModel } from 'core/domain/sensors/models/sensorEntityModels';
import './styles.scss';

export const SensorColumns = (
  onEdit: (sensor: SensorEntityModel) => void,
  onVisible: (sensor: SensorEntityModel, visible: boolean) => void
): AlfredTableColumn<SensorEntityModel>[] => {
  const { t } = useTranslation();

  const getMenuExtra = (sensor: SensorEntityModel): ReactElement => (
    <Menu>
      <Menu.Item key={'edit_name'} onClick={() => onEdit(sensor)}>
        {t('EDIT_NAME')}
      </Menu.Item>
      <Menu.Item key={'is_visible'} onClick={() => onVisible(sensor, !sensor.canDataBeFetched)}>
        <SpaceAtom>
          <Switch size={'small'} checked={sensor.canDataBeFetched} onChange={(check) => onVisible(sensor, check)} />
          {t('SHOW_IN_STATISTICS')}
        </SpaceAtom>
      </Menu.Item>
    </Menu>
  );

  return [
    {
      title: `${t('name')}`,
      key: 'name',
      render: (text, { name, canDataBeFetched }) => (
        <div className={`SensorColumns__text${canDataBeFetched ? '' : '--isNotVisible'}`}>{name}</div>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      render: (text, { id, canDataBeFetched }) => (
        <div className={`SensorColumns__text${canDataBeFetched ? '' : '--isNotVisible'}`}>{id}</div>
      ),
    },
    {
      title: `${t('type')}`,
      key: 'sensorType',
      render: (text, { sensorType, canDataBeFetched }) => (
        <div className={`SensorColumns__text${canDataBeFetched ? '' : '--isNotVisible'}`}>{sensorType}</div>
      ),
    },
    {
      title: `${t('room')}`,
      key: 'room',
      render: (text, { room, canDataBeFetched }) => (
        <div className={`SensorColumns__text${canDataBeFetched ? '' : '--isNotVisible'}`}>{room}</div>
      ),
    },
    {
      title: `${t('usage')}`,
      key: 'usage',
      render: (text, { usage, canDataBeFetched }) => (
        <div className={`SensorColumns__text${canDataBeFetched ? '' : '--isNotVisible'}`}>{usage}</div>
      ),
    },
    {
      title: t('actions'),
      key: 'menu',
      width: '5%',
      render: (text, sensor) => <ContextualMenu menu={getMenuExtra(sensor)} />,
    },
  ];
};
